import * as React from 'react';
import { HeadFC } from 'gatsby';
import { SEO } from '../components/Seo';
import { PageLayout } from '../components/PageLayout';
import { graphql } from 'gatsby';
import { CONTAINER_SIZE, routes } from '../core/constants';
import { Link as GatsbyLink } from 'gatsby';
import {
  Container,
  Box,
  Heading,
  Divider,
  Text,
  Image,
} from '@chakra-ui/react';
import './index.css';
import { BlogPostPublishedAt } from '../components/BlogPostPublishedAt';
import { BlogPostTags } from '../components/BlogPostTags';

type BlogPostProps = {
  data: {
    coverImg: {
      childImageSharp: {
        gatsbyImageData: { images: { fallback: { src: string } } };
      };
    };
    summary: string;
    coverImgAlt: string;
    publishedAt: string;
    type: string;
    tags: string;
    title: string;
    slug: string;
  };
};

type BlogPageProps = {
  data: {
    allMarkdownRemark: {
      nodes: {
        frontmatter: BlogPostProps['data'];
      }[];
    };
  };
};

const BlogPost: React.FC<BlogPostProps> = ({
  data: { coverImg, coverImgAlt, publishedAt, slug, summary, tags, title },
}) => {
  const coverImgSrc =
    coverImg.childImageSharp.gatsbyImageData.images.fallback.src;

  return (
    <Box mt={12}>
      <Box as={GatsbyLink} to={routes.blog(slug)}>
        <Image
          mb={2}
          borderRadius={'md'}
          src={coverImgSrc}
          alt={coverImgAlt}
          loading={'lazy'}
          width={96}
          maxW={'100%'}
        />
        <BlogPostTags tags={tags} />
        <Heading as="h2" size="md" mb={1}>
          {title}
        </Heading>
        <Text>{summary}</Text>
        <BlogPostPublishedAt mt={2} publishedAt={publishedAt} />
      </Box>
    </Box>
  );
};

const BlogPage: React.FC<BlogPageProps> = ({ data }) => {
  const blogPostData = data.allMarkdownRemark.nodes
    .map((node) => node.frontmatter)
    .filter((node) => node.type === 'blog-post')
    .sort((a, b) =>
      +new Date(a.publishedAt) < +new Date(b.publishedAt) ? 1 : -1,
    ); // sort by order ascending

  return (
    <PageLayout>
      <Container maxW={CONTAINER_SIZE} py={10}>
        <Heading as="h1" size="lg" mt={0} mb={1}>
          Welcome to our blog
        </Heading>
        <Divider mt={4} />
        {blogPostData.map((blogPost, index) => (
          <React.Fragment key={blogPost.slug}>
            <BlogPost data={blogPost} />
            {index < blogPostData.length - 1 && <Divider mt={12} />}
          </React.Fragment>
        ))}
      </Container>
    </PageLayout>
  );
};

export default BlogPage;

export const Head: HeadFC = () => (
  <SEO
    title={`Blog - instatab`}
    ogTitle={`instatab - Blog`}
    ogUrl={`https://instatab.io/blog`}
  />
);

export const query = graphql`
  query BlogPage {
    allMarkdownRemark {
      nodes {
        frontmatter {
          tags
          publishedAt
          title
          slug
          type
          summary
          coverImg {
            childImageSharp {
              gatsbyImageData
            }
          }
          coverImgAlt
        }
      }
    }
  }
`;
